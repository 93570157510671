// ---- fonts ----
@import './assets/styles/fonts/fonts.scss';

@import './assets/styles/main.scss';

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

body {
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow-x: hidden;
}

* {
  font-family: poppins;
  box-sizing: border-box;
}

.limiter {
  width: 100%;
  margin: 0 auto;
  padding: 0px 24px;
  max-width: 800px;
}

.toast-container {
  position: fixed !important;
  transform: translateX(calc(-50vw + 50%)) !important;
}

.no-select {
  user-select: none;
}

.content-visibility-auto {
  content-visibility: auto;
}
