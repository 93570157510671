.custom-loader {
  width: 50px;
  height: 50px;
  display: inline-block;
  position: relative;

  &::after,
  &::before {
    content: '';
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #fff;
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 2s linear infinite;
  }

  &.dark {
    &::after,
    &::before {
      border: 3px solid #0f0700;
    }
  }

  &::after {
    animation-delay: 1s;
  }
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
