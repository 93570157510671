.apple-switch {
  position: relative;
  appearance: none;
  outline: none;
  width: 40px;
  min-width: 40px;
  height: 26px;
  background-color: #ffffff;
  border: 1px solid #d9dadc;
  border-radius: 50px;
  box-shadow: inset -15px 0 0 0 #e6e5e5;
  transition-duration: 500ms;
  &.disabled {
    filter: contrast(0.3) brightness(1.4) !important;
    cursor: not-allowed !important;
    pointer-events: none !important;
  }

  &:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 21.5px;
    height: 21.5px;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 1px 1px 6px #48484833;
    transition-duration: 500ms;
  }

  &:checked {
    border-color: var(--secondaryColor);
    box-shadow: inset 15px 0px 0 0 var(--secondaryColor);
  }

  &:checked:after {
    left: 16px;
    box-shadow: 1px 4px 1px #0000000f;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed !important;
  }
}
