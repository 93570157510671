.custom-card {
  display: grid;
  grid-template-areas:
    'head'
    'body'
    'footer';
  grid-template-rows: min-content auto min-content;

  &.bg-white {
    background-color: white;
  }

  &.card-rounded {
    border-radius: 15px;
  }

  &.card-shadow {
    box-shadow: 0px 2px 5px #00000038;

    &.shadow-brown {
      box-shadow: 0px 2px 5px #83460945 !important;
    }
  }

  .custom-card-header {
    grid-area: head;
  }
  .custom-card-body {
    grid-area: body;
  }
  .custom-card-footer {
    grid-area: footer;
  }

  .custom-card-header,
  .custom-card-body,
  .custom-card-footer {
    max-width: 100%;
    overflow: hidden; /* Esconde cualquier contenido que se salga */
    box-sizing: border-box;
  }
}
