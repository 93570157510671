dialog {
  transition: scale 0.3s ease, display 0.3s ease allow-discrete;
  scale: 0;
  z-index: 101;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 2px 3px 1px #00000014;

  &[open] {
    scale: 1;
    transition: scale 0.3s ease-in-out;
    max-width: 90%;

    @starting-style {
      scale: 0;
    }
  }
}

.dialog-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fbe6b425;
  display: none;
  z-index: 100;
  align-items: center;
  backdrop-filter: blur(3px) !important;
  transition: display 0.3s ease allow-discrete;

  &.show {
    display: flex;

    @starting-style {
      scale: 0;
    }
  }
}
