.custom-tag {
  padding: 5px;
  border-radius: 10px;

  width: max-content;
  &.c-primary {
    background-color: var(--primaryColorOpacityMid);
    color: var(--secondaryColor);
  }

  &.c-success {
    background-color: var(--light-green);
    color: var(--green);
  }

  &.c-danger {
    background-color: var(--light-dangerColor);
    color: var(--dangerColor);
  }
}
