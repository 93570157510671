@-webkit-keyframes slideUp {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideUp {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes slideRotate180Down {
  from {
    -webkit-transform: rotate(180deg) translateY(100%);
    transform: rotate(180deg) translateY(100%);
    visibility: visible;
  }

  to {
    -webkit-transform: rotate(180deg) translateY(0);
    transform: rotate(180deg) translateY(0);
  }
}
@keyframes slideRotate180Down {
  from {
    -webkit-transform: rotate(180deg) translateY(100%);
    transform: rotate(180deg) translateY(100%);
    visibility: visible;
  }

  to {
    -webkit-transform: rotate(180deg) translateY(0);
    transform: rotate(180deg) translateY(0);
  }
}


@-webkit-keyframes slideTraslate-22Down {
  from {
    -webkit-transform: translateX(-22%) translateY(-100%);
    transform: translateX(-22%) translateY(-100%);
    visibility: visible;
  }

  to {
    -webkit-transform: translateX(-22%) translateY(0);
    transform: translateX(-22%) translateY(0);
  }
}
@keyframes slideTraslate-22Down {
  from {
    -webkit-transform: translateX(-22%) translateY(-100%);
    transform: translateX(-22%) translateY(-100%);
    visibility: visible;
  }

  to {
    -webkit-transform: translateX(-22%) translateY(0);
    transform: translateX(-22%) translateY(0);
  }
}

@-webkit-keyframes slideTraslate-0Down {
  from {
    -webkit-transform: translateX(0%) translateY(-100%);
    transform: translateX(0%) translateY(-100%);
    visibility: visible;
  }

  to {
    -webkit-transform: translateX(0%) translateY(0);
    transform: translateX(0%) translateY(0);
  }
}
@keyframes slideTraslate-0Down {
  from {
    -webkit-transform: translateX(0%) translateY(-100%);
    transform: translateX(0%) translateY(-100%);
    visibility: visible;
  }

  to {
    -webkit-transform: translateX(0%) translateY(0);
    transform: translateX(0%) translateY(0);
  }
}

@-webkit-keyframes slideTraslate-22Up {
  from {
    -webkit-transform: translateX(-22%) translateY(100%);
    transform: translateX(-22%) translateY(100%);
    visibility: visible;
  }

  to {
    -webkit-transform: translateX(-22%) translateY(0);
    transform: translateX(-22%) translateY(0);
  }
}
@keyframes slideTraslate-22Up {
  from {
    -webkit-transform: translateX(-22%) translateY(100%);
    transform: translateX(-22%) translateY(100%);
    visibility: visible;
  }

  to {
    -webkit-transform: translateX(-22%) translateY(0);
    transform: translateX(-22%) translateY(0);
  }
}
@-webkit-keyframes slideTraslate-0Up {
  from {
    -webkit-transform: translateX(0%) translateY(100%);
    transform: translateX(0%) translateY(100%);
    visibility: visible;
  }

  to {
    -webkit-transform: translateX(0%) translateY(0);
    transform: translateX(0%) translateY(0);
  }
}
@keyframes slideTraslate-0Up {
  from {
    -webkit-transform: translateX(0%) translateY(100%);
    transform: translateX(0%) translateY(100%);
    visibility: visible;
  }

  to {
    -webkit-transform: translateX(0%) translateY(0);
    transform: translateX(0%) translateY(0);
  }
}
