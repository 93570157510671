/* ------  gap ------ */
.gap-1 {
    gap: 0.2rem !important;
  }
  
  .gap-2 {
    gap: 0.5rem !important;
  }
  
  .gap-3 {
    gap: 1rem !important;
  }
  
  .gap-4 {
    gap: 1.5rem !important;
  }
  
  .gap-5 {
    gap: 2rem !important;
  }
  
  /* ------ column gap ------ */
  
  .column-gap-1 {
    column-gap: 0.2rem !important;
  }
  
  .column-gap-2 {
    column-gap: 0.5rem !important;
  }
  
  .column-gap-3 {
    column-gap: 1rem !important;
  }
  
  .column-gap-4 {
    column-gap: 1.5rem !important;
  }
  
  .column-gap-5 {
    column-gap: 2rem !important;
  }
  
  .column-gap-6 {
    column-gap: 2.5rem !important;
  }
  
  .column-gap-7 {
    column-gap: 3rem !important;
  }
  
  .column-gap-8 {
    column-gap: 3.5rem !important;
  }
  
  /* ------ row gap ------ */
  .row-gap-1 {
    row-gap: 0.2rem !important;
  }
  
  .row-gap-2 {
    row-gap: 0.5rem !important;
  }
  
  .row-gap-3 {
    row-gap: 1rem !important;
  }
  
  .row-gap-4 {
    row-gap: 1.5rem !important;
  }
  
  .row-gap-5 {
    row-gap: 2rem !important;
  }
  
  .row-gap-6 {
    row-gap: 2.5rem !important;
  }
  
  .row-gap-7 {
    row-gap: 3rem !important;
  }
  
  .row-gap-8 {
    row-gap: 3.5rem !important;
  }
  
  /* spacing */
  .layout-spacing-1 {
    margin-bottom: 0.1rem !important;
  }
  
  .layout-spacing-2 {
    margin-bottom: 0.2rem !important;
  }
  
  .layout-spacing-3 {
    margin-bottom: 0.3rem !important;
  }
  
  .layout-spacing-4 {
    margin-bottom: 0.4rem !important;
  }
  
  .layout-spacing-5 {
    margin-bottom: 0.5rem !important;
  }
  
  .layout-spacing-6 {
    margin-bottom: 0.6rem !important;
  }
  
  .layout-spacing-7 {
    margin-bottom: 0.7rem !important;
  }
  
  .layout-spacing-8 {
    margin-bottom: 0.8rem !important;
  }
  
  .layout-spacing-9 {
    margin-bottom: 0.9rem !important;
  }
  
  .layout-spacing-10 {
    margin-bottom: 1rem !important;
  }
  
  