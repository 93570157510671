.btn {
  border-radius: 10px;
  border: none;

  &.btn-text-primary {
    color: var(--secondaryColor);
    &:hover {
      background-color: var(--primaryColorOpacityLow);
    }
    &:active {
      background-color: var(--primaryColorOpacityLow);
      filter: brightness(0.7);
    }
  }

  &.btn-primary {
    color: #080400;
    background-color: var(--primaryColor);

    &:hover {
      filter: brightness(0.9);
    }
    &:active {
      background-color: var(--primaryColor);
      filter: brightness(0.7);
    }
  }

  &.btn-light {
    color: #080400;
    background-color: #0000001c;
    &:hover {
      background-color: #00000028;
    }
    &:active {
      background-color: #0000003f;
    }
  }

  &.circular {
    border-radius: 50% !important;
  }
}
