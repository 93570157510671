/* ----- text color -----  */

.text-c-danger {
  color: var(--dangerColor) !important;
}

.text-c-primary {
  color: var(--primaryColor) !important;
}

.text-c-secondary {
  color: var(--secondaryColor) !important;
}

.text-c-tertiary {
  color: var(--tertiaryColor) !important;
}

.text-c-tertiary-dark {
  color: var(--tertiaryColorDark) !important;
}

.text-c-green {
  color: var(--green) !important;
}

.text-c-danger {
  color: var(--dangerColor) !important;
}

.text-c-white {
  color: white !important;
}

.text-c-white-20 {
  color: rgba(255, 255, 255, 0.2) !important;
}

.text-c-white-30 {
  color: rgba(255, 255, 255, 0.3) !important;
}

.text-c-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-c-white-70 {
  color: rgba(255, 255, 255, 0.7) !important;
}

.text-c-white-90 {
  color: rgba(255, 255, 255, 0.9) !important;
}

.text-c-black-10 {
  color: rgba(0, 0, 0, 0.1) !important;
}

.text-c-black-20 {
  color: rgba(0, 0, 0, 0.2) !important;
}

.text-c-black-30 {
  color: rgba(0, 0, 0, 0.3) !important;
}

.text-c-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-c-black-70 {
  color: rgba(0, 0, 0, 0.7) !important;
}

.text-c-black-90 {
  color: rgba(0, 0, 0, 0.9) !important;
}

/* ----- text-weight -----  */

.text-w-normal {
  font-weight: normal !important;
}

.text-w-100 {
  font-weight: 100 !important;
}

.text-w-300 {
  font-weight: 300 !important;
}

.text-w-400 {
  font-weight: 400 !important;
}

.text-w-500 {
  font-weight: 500 !important;
}

.text-w-600 {
  font-weight: 600 !important;
}

.text-w-700 {
  font-weight: 700 !important;
}

/* ----- text-size -----  */

.text-s-small {
  font-size: small;
}

.text-s-12 {
  font-size: 12px !important;
}

.text-s-13 {
  font-size: 13px !important;
}

.text-s-14 {
  font-size: 14px !important;
}

.text-s-16 {
  font-size: 16px !important;
}

.text-s-18 {
  font-size: 18px !important;
}

.text-s-23 {
  font-size: 23px !important;
}

.text-s-25 {
  font-size: 25px !important;
}

.text-s-30 {
  font-size: 30px !important;
}

.text-s-35 {
  font-size: 35px !important;
}

.text-s-40 {
  font-size: 40px !important;
}

.text-s-45 {
  font-size: 45px !important;
}

.text-s-50 {
  font-size: 50px !important;
}

.text-s-55 {
  font-size: 55px !important;
}

/* ----- text extra -----  */
.white-space-nowrap {
  white-space: nowrap !important;
}

.text-balance {
  text-wrap: balance !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

.text-justify {
  text-align: justify;
}

/* ----- text family -----  */

.text-f-glamour {
  font-family: glamour;
}

.text-f-candle {
  font-family: Candlescript;
}

.text-f-tangerine {
  font-family: tangerine;
}

.text-f-vogue {
  font-family: vogue;
}

.text-f-proelium {
  font-family: proelium;
}

/* ----- text shadow -----  */

.text-shadow {
  text-shadow: 1px 1px 3px #00000054;
}

.text-shadow-dark {
  text-shadow: 2px 1px 4px #000000e7;
}
