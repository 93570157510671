:root {
  --primaryColor: #efcd93;
  --primaryColorOpacityMid: #efcd9367;
  --primaryColorOpacityLow: #efcd933d;

  --secondaryColor: #ddab56;
  --secondaryColorOpacityMid: #ddac567d;
  --secondaryColorOpacityLow: #ddac561e;

  --tertiaryColor: #a16f27;
  --tertiaryColorOpacityMid: #a16e2775;
  --tertiaryColorOpacityLow: #a16e2721;
  --tertiaryColorDark: #7d5319;

  --accentColor: #1a3e73;

  --dangerColor: #ef4444;
  --light-dangerColor: #ffb5b5;

  --errorColor: #8b2721;
  --blue: #1f56ae;
  --blueBrilliant: #3d9df4;
  --darkBlue: #182e53;
  --purple: #742dc7;
  --green: #3abd80;
  --light-green: #b4ffc8;
  --darkGreen: #216464;
  --greenLime: #67d572;
  --orange: #f98f07;
  --darkOrange: #644521;
  --lightGreen: #78d2cb;
  --salmon: #eb9771;
  --lightYellow: #ffe499;
  --darkYellow: #a59515;
  --greenHighlight: #f4ffd5;

  --orangeTransparent: #feb5004d;

  --bg-light: #f4f7f7;
  --bg-light-blue: #eff8ff;
  --bg-light-blue-opacity: #eff8ff5b;
  --bg-light-danger: #ffefef;
  --bg-light-danger-opacity: #ffefef4b;
  --bg-light-green: #e6f8e8;
  --bg-light-green-opacity: #e6f8e843;
  --bg-light-orange: #fdf5eb;
  --bg-light-orange-opacity: #fdf5eb5e;
  --bg-light-yellow: #fffcdf;
  --bg-light-yellow-opacity: #fbf9e84a;
  --mutedColor: #768ea7;
  --darkMutedColor: #44525a;
  --darkColor: #000206;
  --grey: #c9d4d8;
  --light-grey: #dae1e4;
  --dark-grey: #797980;
  --black: #121211;
  --white: #ffffff;
}
