.form-input {
  border-radius: 6px;
  border: 1px solid #80808047;

  &:focus-visible,
  &:focus {
    border: 2px solid var(--primaryColor);
    outline: none;
  }

  &:not(.no-validate).invalid {
    border: 2px solid var(--dangerColor);
  }

  &.size-lg {
    padding: 0.3rem 1rem;
  }
}

.input-icon-right {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  .form-input {
    padding-right: 3rem;
    width: 100%;
  }
  .icon {
    position: absolute;
    right: 0.5rem;
  }
}
