// Definir una variable para la ruta de la fuente
$font-path: '../../fonts/';

// Lista de variaciones que deseas generar
$font-tangerine-variations: (
  // Tangerine
  ('Tangerine-Bold.woff2', bold, normal, 'tangerine'),
  ('Tangerine-Regular.woff2', normal, normal, 'tangerine'),
  // Poppins
  ('Poppins-Bold.woff2', 700, normal, 'poppins'),
  ('Poppins-Medium.woff2', 500, normal, 'poppins'),
  ('Poppins-Regular.woff2', 400, normal, 'poppins'),
  ('Poppins-Light.woff2', 300, normal, 'poppins'),
  ('Poppins-Thin.woff2', 100, normal, 'poppins'),
  // Glamour
  ('Glamour.woff2', 100, normal, 'glamour'),
  // Proelium
  ('Proelium.woff2', 500, normal, 'proelium'),
  // Candlescript
  ('Candlescript.woff2', 200, normal, 'candlescript'),
  // Vogue
  ('Vogue.woff2', 200, serif, 'vogue')
);

// Definir el @font-face utilizando un @for
@for $i from 1 through length($font-tangerine-variations) {
  $font-variation: nth($font-tangerine-variations, $i);
  $font-name: nth($font-variation, 1);
  $font-weight: nth($font-variation, 2);
  $font-style: nth($font-variation, 3);
  $font-family-name: nth($font-variation, 4);

  @font-face {
    font-family: '#{$font-family-name}';
    src: url('#{$font-path}#{$font-name}') format('truetype');
    font-display: swap;
    font-weight: $font-weight;
    font-style: $font-style;
  }
}
