p-selectbutton {
  .p-selectbutton {
    .p-button {
      padding: 0.3rem;

      &.p-focus,
      &:focus,
      &.focus {
        background: transparent;
        &.p-highlight {
          background-color: var(--primaryColorOpacityMid);
        }
      }

      &.p-highlight {
        background-color: var(--primaryColorOpacityMid);
      }
    }
  }
}
