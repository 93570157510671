.br-4 {
  border-radius: 4px;
}

.br-10 {
  border-radius: 10px;
}

.br-15 {
  border-radius: 15px;
}
