.form-textarea {
  border-radius: 6px;
  border: 1px solid #80808047;
  overflow-y: auto;
  resize: none;
  padding: 10px;

  &:focus-visible,
  &:focus {
    border: 2px solid var(--primaryColor);
    outline: none;
  }

  &.invalid {
    border: 2px solid var(--dangerColor);
  }
}
