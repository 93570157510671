.fade-in {
  --fade_in_duration: 0.2s;
  opacity: 0;
  animation: fadeInAnimation var(--fade_in_duration) ease forwards;
}

@keyframes fadeInAnimation {
  to {
    opacity: 1;
  }
}
